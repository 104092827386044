import React, { useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import NewSpaceCardShortVersion from '@/components/reusable/NewSpaceCardShortVersion/NewSpaceCardShortVersion';
import Label from '@/components/form/Label/Label';
import dayjs from 'dayjs';
import './ReservationSuccess.scss';

require('dayjs/locale/es');
dayjs.locale('es');

const SpaceDetailInfo = (props) => {
  const { spaceDetail } = props;
  return (
    <FlexContainer className={'flex-column format100'}>
      <NewSpaceCardShortVersion
        spaceInfo={spaceDetail}
        isInlineStyle
        imgUrl={spaceDetail?.main_image}
        spaceTitle={spaceDetail?.space_title}
        spaceDescription={spaceDetail?.space_details}
        price={spaceDetail?.price}
        disableButton
        wifi
        coffee
        parking
        isWhiteBg
      />
    </FlexContainer>
  );
};

const PaymentSection = (props) => {
  const { spaceDetail, formValues, reservation } = props;
  console.log('Datos pasados a reservation a paymentsection :', reservation);
  const data = reservation[0];

  const date = dayjs(formValues?.startDate?.$d);
  const formattedDate = date.format('dddd D [de] MMMM [de] YYYY');

  //const mainGuestLabel = 'Invitado responsable: ' + formValues?.name;
  const mainGuestLabel = 'Invitado responsable: Sofia';
  const guestLabel = 'Personas: ' + formValues?.guestQuantyty + ' personas';
  const dateLabel = 'Fecha de reserva: ' + formattedDate;
  const startTime = 'Hora de inicio: ' + formValues?.startTime + ':00 horas';
  const endTime = 'Hora de Fin: ' + formValues?.endTime + ':00 horas';

  const finalPrice = data.final_price;
  const initialPrice = data.initial_price;
  const paymentMethod = data.method_name;
  const paymentDetails = data.method_details;
  const guestName = `${data.guest_name} ${data.guest_lastname}`;
  const hostName = `${data.nom_host} ${data.host_lastname}`;
  const transactionId = data.transaction_id;
  const state = data.state_name;
  const spaceName = data.space_name;
  const subtype = data.subtype_title;
  const startDate = dayjs(data.start_date).format('dddd D [de] MMMM [de] YYYY');
  const finishDate = dayjs(data.finish_date).format('dddd D [de] MMMM [de] YYYY');

  return (
    <FlexContainer className={'flex-column text-align-justify justify-around m-padlr10'}>
      <FlexContainer className="flex-column bgBlue400 fontWhite formatAuto padlr20 padtb20 text-14 width100">
        <label className={'Subtitle-3 fontWhite margb10 text-16'}>IMPORTANTE: </label>
        <label className={'fontGray050'}>
          Para crear tu acceso al lugar, un miembro de Popnest te contactará por WhatsApp en breve.
        </label>
      </FlexContainer>
      <label className={'text-18 m-text-20 Subtitle-3 margt40 margb10'}>Datos de tu reserva</label>
      <label className={'text-14 m-text-12 margt10'}>Lugar: {spaceName}</label>
      <label className={'text-14 m-text-12 margt10'}>
        Fecha: {startDate}-{finishDate}
      </label>
      <label className={'text-14 m-text-12 margt10'}>{guestLabel}</label>
      <label className={'text-14 m-text-12 margt10'}>{mainGuestLabel}</label>
      <label className={'text-18 m-text-20 Subtitle-3 margtb10'}>Detalle del pago</label>
      <label className={'text-14 m-text-12 margt10'}>
        Método de pago: {paymentMethod} ({paymentDetails})
      </label>
      <label className={'text-14 m-text-12 margt10'}>Subtotal: ${finalPrice}</label>

      <FlexContainer className={'justify-between borderGray100 justify-between padlr10 padtb10 margt10'}>
        <label className={'text-16 m-text-14'}>
          <strong>Total</strong>
        </label>
        <label>
          <strong className={'text-20 m-text-18'}>${finalPrice}</strong>
        </label>
      </FlexContainer>
      <label className={'text-18 m-text-20 Subtitle-3 margtb10'}>Como llegar</label>
      <label className={'text-18 m-text-20 Subtitle-3 margtb10'}>Como acceder</label>
      <label className={'text-14 m-text-12 '}>
        El lugar es en <strong>{spaceName} </strong> podrás ubicarte en los espacios disponibles,
        usa el de tu preferencia{' '}
        <strong>Nuestro staff de Popnest te brindará el código de acceso por Whatsapp </strong>
      </label>
      <button
        className={
          'bgBlue400 width100 border-rad24px borderGray050 fontWhite grayShadow16 margtb30 padlr40 padtb10 text-18'
        }
      >
        <strong>Compartir boucher</strong>
      </button>
    </FlexContainer>
  );
};

const GiftSection = (props) => {
  const { spaceDetail, formValues, reservation } = props;
  console.log('Datos pasados a reservation a paymentsection :', reservation);
  const data = reservation[0];

  const date = dayjs(formValues?.startDate?.$d);
  const formattedDate = date.format('dddd D [de] MMMM [de] YYYY');

  //const mainGuestLabel = 'Invitado responsable: ' + formValues?.name;
  const mainGuestLabel = 'Regalo para: ' + data?.beneficiary;
  const guestLabel = 'De: ' + data?.gifter;
  const dateLabel = 'Fecha de reserva: Por seleccionar';
  const startTime = 'Hora de inicio: Por seleccionar';
  const endTime = 'Hora de Fin: Por seleccionar';

  const finalPrice = data.final_price;
  const initialPrice = data.initial_price;
  const paymentMethod = data.method_name;
  const paymentDetails = data.method_details;

  const transactionId = data.transaction_id;
  const state = data.state_name;
  const spaceName = data.space_name;
  const message = 'Mensaje de ' + data?.gifter + ' para ' + data?.beneficiary + ': ' + data.message;

  return (
    <FlexContainer className={'flex-column text-align-justify justify-around m-padlr10'}>
      <Label className="width100 text-16">
        Estimado/a usuario/a: Estamos emocionados de que hayas reservado un espacio en{' '}
        {spaceDetail?.space_title} para tu próxima sesión de {spaceDetail?.subtype_title}.
      </Label>
      <FlexContainer className="padtb10 bgGray025 m-margtb10 margtb10" />
      <label className={'text-14 m-text-12 bold'}>Datos de la reserva:</label>
      <label className={'text-14 m-text-12 '}>{mainGuestLabel}</label>
      <label className={'text-14 m-text-12 '}>{guestLabel}</label>
      <label className={'text-14 m-text-12 '}>{message}</label>
      <FlexContainer className="padtb10 bgGray025 m-margtb10 margtb10" />
      <label className={'text-14 m-text-12 '}>{dateLabel}</label>
      <label className={'text-14 m-text-12 '}>{startTime}</label>
      <label className={'text-14 m-text-12 '}>{endTime}</label>
      <label className={'text-14 m-text-12 '}>Detalles de la reserva:</label>
      <label className={'text-14 m-text-12 '}>
        Método de pago: {paymentMethod} ({paymentDetails})
      </label>
      <label className={'text-14 m-text-12 '}>ID de transacción: {transactionId}</label>
      <label className={'text-14 m-text-12 '}>Precio inicial: ${initialPrice}</label>
      <label className={'text-14 m-text-12 '}>Precio final: ${finalPrice}</label>
      <label className={'text-14 m-text-12 '}>Estado: {state}</label>

      <FlexContainer className="padtb10 bgGray025 m-margtb10 margtb10" />

      <label className={'text-14 m-text-12 '}>Detalles del espacio:</label>
      <label className={'text-14 m-text-12 '}>Espacio: {spaceName}</label>
      <label className={'text-14 m-text-12 '}>Subtipo: Coworking</label>
    </FlexContainer>
  );
};

const ReservationSuccess = (props) => {
  const { spaceDetail, formValues, reservation } = props;

  console.log('Datos de la reservación: ', reservation);

  const spaceJson = {
    space_id: 10,
    id_relationship: 4,
    space_name:
      'Edificio en el corazón de la Condesa! Un exclusivo espacio de coworking de cuatro niveles, donde la luz natural y el diseño moderno te envuelven en un ambiente perfecto para la productividad y la colaboración. Aquí, la innovación y el diseño se unen para crear un espacio donde la creatividad y la colaboración se elevan al máximo',
    space_title: 'The Club Coworking Condesa prueba',
    subtype_title: 'Coworking',
    space_details_general:
      'The Club Coworking en la Condesa ofrece un elegante espacio de trabajo de cuatro niveles en una de las zonas más vibrantes de Ciudad de México, ideal para profesionales en busca de productividad y networking. Entre sus amenidades se encuentran una terraza multifuncional, balcones privados con vistas verdes, seguridad 24/7, internet, salas de reuniones, manejo de correspondencia y servicio de café. Su diseño combina mármol, madera y luz natural, creando un entorno sofisticado. La flexibilidad de acceso y eventos de comunidad completan la experiencia. Terraza multifuncional: ¡Trabaja, disfruta de una taza de café o realiza llamadas en un ambiente relajado y propicio para hacer networking! Excelente accesibilidad: Ubicado a pocos pasos de estaciones de Metrobús y metro, así como de las principales vías de comunicación de la ciudad.',
    space_details:
      'Exclusivos espacios de coworking de cuatro niveles, donde la luz natural y el diseño moderno te envuelven en un ambiente perfecto para la productividad y la colaboración. Aquí, la innovación y el diseño se unen para crear un espacio donde la creatividad y la colaboración se elevan al máximo.',
    host_capacity: 20,
    pet_friendly: '0',
    coffe_water: '0',
    hourly_rate: '2',
    rate_4_hours: '2',
    rate_8_hours: '2',
    state_name: 'Available',
    parking: '0',
    parking_details: 'Sobre la calles es posible estacionarse usando parquímetro',
    main_image: 'spaces/espacio1-condesa.png',
    house_number: 410,
    space_latitude: '19.41061113855626',
    space_logitude: '-99.17615947584562',
    street_reference: 'A 2 cuadras de Av. Tamaulipas y a 4 cuadras de Av. Michoacán',
    zip_code: 6170,
    is_reserved: '0',
    gmaps_url:
      'https://www.google.com/maps/place/The+Club+Coworking+Condesa/@19.4103733,-99.1787451,17z/data=!3m2!4b1!5s0x85d1ff5d49aa360b:0xf0719e967d6dda4d!4m6!3m5!1s0x85d1ff9b21972a99:0xe1eb913f0acae16!8m2!3d19.4103683!4d-99.1761702!16s%2Fg%2F11y3yf28h0?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D',
    space_address: 'Campeche 410, Hipódromo Condesa, Ciudad de México, 06170, México',
  };

  const reservationJson = {
    startDate: {
      $L: 'en',
      $d: '2024-11-20T05:00:00.000Z',
      $y: 2024,
      $M: 10,
      $D: 20,
      $W: 3,
      $H: 0,
      $m: 0,
      $s: 0,
      $ms: 0,
      $x: {},
      $isDayjsObject: true,
    },
    startTime: 10,
    endTime: 14,
    name: 'Mario Enrique Vargas Pino',
    email: 'sistemas@popnest.org',
    phone: '922907079',
    countryCode: '+52',
    guestQuantyty: 1,
  };
  const spaceData = spaceDetail ? spaceDetail : spaceJson;
  const formData = formValues ? formValues : reservationJson;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (reservation) {
    return (
      <PageContainer className={''}>
        <FlexContainer className={'flex-column margt72'}>
          <Label type="Subtitle-3 width100 margt50 padlr20 m-padlr10 m-margt20 fontGray250 text-center text-26 m-text-22">
            ¡RESERVA REALIZADA CON EXITO!
          </Label>
          <FlexContainer className={'m-flex-column margt20 margb120 gap-20'}>
            {reservation[0]?.isgift != 1 && (
              <>
                <SpaceDetailInfo spaceDetail={spaceData} formValues={formData} />
                <PaymentSection
                  spaceDetail={spaceData}
                  formValues={formData}
                  reservation={reservation}
                />
              </>
            )}
            {reservation[0]?.isgift == 1 && (
              <>
                <GiftSection
                  spaceDetail={spaceData}
                  formValues={formData}
                  reservation={reservation}
                />
              </>
            )}
          </FlexContainer>
        </FlexContainer>
      </PageContainer>
    );
  } else {
    return <></>;
  }
};

export default ReservationSuccess;
