import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { useNavigate } from 'react-router-dom';
import Label from '@/components/form/Label/Label';
import NewSpaceCardShortVersion from '@/components/reusable/NewSpaceCardShortVersion/NewSpaceCardShortVersion';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './SpacesList.scss';

const SpacesDataRow = (props) => {
  const { spacesData, goToSpacesPreList } = props;
  const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };

  const groupedSpaces = chunkArray(spacesData || [], 4);
  const containerClass = 'bgWhite padlr20 gap-20 margb20 t-hide m-hide format100';

  return (
    <>
      {groupedSpaces.map((group, groupIndex) => (
        <FlexContainer key={groupIndex} className={containerClass} justifyBetween>
          {group.map((space, index) => (
            <NewSpaceCardShortVersion
              key={index}
              spaceInfo={space}
              handleDetailButton={() => goToSpacesPreList(space)}
              priceAndCapacityDetails
            />
          ))}
          {groupIndex === groupedSpaces.length - 1 &&
            group.length < 4 &&
            Array.from({ length: 4 - group.length }).map((_, emptyIndex) => (
              <FlexContainer
                className={'padlr20 padtb20 flex-column justify-between format100'}
                key={emptyIndex}
              ></FlexContainer>
            ))}
        </FlexContainer>
      ))}
    </>
  );
};

const SpacesList = (props) => {
  const { customTitle, buttonTitle, isShowButton, spacesData } = props;
  const navigate = useNavigate();

  const goToSpacesPreList = (spaceData) => {
    console.log('ver la data', spaceData);
    //navigate('/productPreList');
    navigate('/productDetails', { state: { spaceData } });
  };
  const goToSpacesList = () => {
    navigate('/spaces');
  };

  return (
    <PageContainer className={'bgWhite'}>
      <FlexContainer
        className={'flex-column margt20 margb60 mySpacesList bgWhite format100'}
        justifyBetween
        alignItemsStart
      >
        {customTitle && (
          <Label type="Subtitle-1 text-20 bold marglr20 padtb20">{customTitle}</Label>
        )}
        <SpacesDataRow spacesData={spacesData} goToSpacesPreList={goToSpacesPreList} />
        <Swiper
          navigation
          autoplay={{
            delay: 10500,
            disableOnInteraction: true,
          }}
          modules={[Autoplay, Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          className="d-hide"
        >
          {spacesData?.length >= 1 &&
            spacesData.map((space, index) => (
              <SwiperSlide key={index}>
                <NewSpaceCardShortVersion
                  spaceInfo={space}
                  handleDetailButton={() => goToSpacesPreList(space)}
                  priceAndCapacityDetails
                />
              </SwiperSlide>
            ))}
        </Swiper>
        {isShowButton && (
          <FlexContainer className={'flex-column width100'} alignItemsCenter>
            <button
              onClick={goToSpacesList}
              className={
                'bgPurple4 bold border-rad8px borderGray050 fontWhite grayShadow16 margt50 margb20 padlr40 padtb10'
              }
            >
              {buttonTitle}
            </button>
          </FlexContainer>
        )}
      </FlexContainer>
    </PageContainer>
  );
};

export default SpacesList;
