import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { GoogleMap, Marker, useJsApiLoader, Circle } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import { CommentSection } from 'react-comments-section';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import SpacesList from '@/components/modules/SpacesList/SpacesList';
import NewSpaceCardShortVersion from '@/components/reusable/NewSpaceCardShortVersion/NewSpaceCardShortVersion';
import NewSpaceCardDetailVersion from '@/components/reusable/NewSpaceCardDetailVersion/NewSpaceCardDetailVersion';
import NewSpaceCard from '@/components/reusable/NewSpaceCard/NewSpaceCard';
import Label from '@/components/form/Label/Label';
import classNames from 'classnames';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './ProductPreListView.scss';
import 'react-comments-section/dist/index.css';

const GoogleMapComponent = (props) => {
  const { center, markers, detail } = props;
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC75q_cO4SIMGhQgRJwAIpNfNQ5cXwVXnU',
  });

  const [map, setMap] = React.useState(null);

  const containerStyle = {
    width: '100%',
    height: '100%',
    minHeight: '300px',
  };

  const mapStyles = [
    {
      featureType: 'all',
      elementType: 'geometry',
      stylers: [{ saturation: -30 }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#f5f3e5' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#e0e0e0' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#d9e3f0' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e9d3' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [{ color: '#6c75f0' }],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#6c75f0' }],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#808080' }],
    },
    {
      featureType: 'administrative.province',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#808080' }],
    },
  ];

  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds(center);
    //map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const customIcon = {
    url: markers ? markers[0]?.image : '',
    scaledSize: new window.google.maps.Size(60, 60),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(30, 30),
  };

  const isPreListMapReady = markers && !detail;
  const isReadyToMap = isLoaded && markers;

  const setSelectedSpaceFromMaker = (marker, space) => {
    console.log('ver el espacio', space);
    setSelectedSpace(space);
    setSelectedMarker(marker);
  };

  return isReadyToMap ? (
    <FlexContainer className={'flex-column height100 width100'}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={13}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          styles: mapStyles,
        }}
      >
        {isPreListMapReady &&
          markers
            .map((space) => ({
              lat: parseFloat(space.space_latitude.trim()),
              lng: parseFloat(space.space_logitude.trim()),
              spaceData: space,
            }))
            .filter((space) => !isNaN(space.lat) && !isNaN(space.lng))
            .map((space, index) => (
              <Marker
                key={index}
                position={{ lat: space.lat, lng: space.lng }}
                onClick={() => setSelectedSpaceFromMaker(index, space)}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  fillColor: selectedMarker === index ? '#022b54' : '#ffffff', // Color de relleno del seleccionado
                  fillOpacity: 1,
                  strokeColor: '#022b54', // Borde azul
                  strokeWeight: 2,
                  scale: selectedMarker === index ? 15 : 12, // Aumenta tamaño al seleccionar
                }}
              />
            ))}
        {detail && (
          <>
            {/* Renderizar el marcador */}
            <Marker
              key={1}
              position={{ lat: markers[0]?.lat, lng: markers[0]?.lng }}
              icon={customIcon}
              onClick={() =>
                console.log(`Clicked marker at ${markers[0]?.lat}, ${markers[0]?.lng}`)
              }
            />

            {/* Renderizar el círculo alrededor del marcador */}
            <Circle
              center={{ lat: markers[0]?.lat, lng: markers[0]?.lng }}
              radius={500} // Ajusta el radio en metros
              options={{
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35,
              }}
            />
          </>
        )}
      </GoogleMap>
      {selectedSpace && (
        <NewSpaceCardShortVersion
          key={1}
          spaceInfo={selectedSpace?.spaceData}
          handleDetailButton={() => console.log('espacio')}
          priceAndCapacityDetails
        />
      )}
    </FlexContainer>
  ) : (
    <></>
  );
};

const IndividualAmenitie = (props) => {
  const { type } = props;
  return (
    <FlexContainer className={'border-rad10px borderBlack margtb5 padlr6 padtb6 fontGray300'}>
      {type === 'wifi' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-wifi m-text-10 text-12'} />
          <Label className={'text-10'}>Wifi</Label>
        </FlexContainer>
      )}
      {type === 'coffee' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-coffee m-text-10 text-12'} />
          <Label className={'text-10'}>Café Ilimitado</Label>
        </FlexContainer>
      )}
      {type === 'parking' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-car-side m-text-10 text-12'} />
          <Label className={'text-10'}>Estacionamiento</Label>
        </FlexContainer>
      )}
      {type === 'staff' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-users m-text-10 text-12'} />
          <Label className={'text-10'}>Staff</Label>
        </FlexContainer>
      )}
      {type === 'cleaning_service' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-broom m-text-10 text-12'} />
          <Label className={'text-10'}>Cleaning Service</Label>
        </FlexContainer>
      )}
      {type === 'printing_service' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-print m-text-10 text-12'} />
          <Label className={'text-10'}>Printing Service</Label>
        </FlexContainer>
      )}
      {type === 'common_areas' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-building  m-text-10 text-12'} />
          <Label className={'text-10'}>Common Areas</Label>
        </FlexContainer>
      )}
      {type === 'convention_hall' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-chalkboard  m-text-10 text-12'} />
          <Label className={'text-10'}>Convention Hall</Label>
        </FlexContainer>
      )}
      {type === 'snacks' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-candy-cane  m-text-10 text-12'} />
          <Label className={'text-10'}>Snacks</Label>
        </FlexContainer>
      )}
      {type === 'phone_service' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-phone  m-text-10 text-12'} />
          <Label className={'text-10'}>Phone Service</Label>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

const AmenitiesSection = (props) => {
  const {
    wifi,
    coffee,
    parking,
    staff,
    cleaning_service,
    printing_service,
    common_areas,
    convention_hall,
    snacks,
    phone_service,
  } = props;
  return (
    <FlexContainer className={'width100'}>
      <FlexContainer
        className={'items-align-start width100 margt20 align-items-center fontGray075 gap-10'}
        style={{
          flexWrap: 'wrap',
        }}
      >
        {wifi && <IndividualAmenitie type="wifi" />}
        {coffee && <IndividualAmenitie type="coffee" />}
        {parking && <IndividualAmenitie type="parking" />}
        {staff && <IndividualAmenitie type="staff" />}
        {cleaning_service && <IndividualAmenitie type="cleaning_service" />}
        {printing_service && <IndividualAmenitie type="printing_service" />}
        {common_areas && <IndividualAmenitie type="common_areas" />}
        {convention_hall && <IndividualAmenitie type="convention_hall" />}
        {snacks && <IndividualAmenitie type="snacks" />}
        {phone_service && <IndividualAmenitie type="phone_service" />}
      </FlexContainer>
    </FlexContainer>
  );
};

const SpaceDetailInfo = (props) => {
  const { closeButton, spaceData, spacesDataFromBackend } = props;
  const navigate = useNavigate();
  const goToSpaceDetails = () => {
    navigate('/productDetails', { state: { spaceData } });
  };
  const wifi = true;
  const coffee = spaceData?.coffe_water === '1' ? true : false;
  const parking = spaceData?.parking === '1' ? true : false;
  const staff = spaceData?.staff === '1' ? true : false;
  const cleaning_service = spaceData?.cleaning_service === '1' ? true : false;
  const printing_service = spaceData?.printing_service === '1' ? true : false;
  const common_areas = spaceData?.common_areas === '1' ? true : false;
  const convention_hall = spaceData?.convention_hall === '1' ? true : false;
  const snacks = spaceData?.snacks === '1' ? true : false;
  const phone_service = spaceData?.phone_service === '1' ? true : false;

  const data = [];

  const imagesArray = (() => {
    try {
      const parsed = JSON.parse(spaceData?.main_image);
      return Array.isArray(parsed) && parsed.length > 0 ? parsed : [spaceData?.main_image];
    } catch {
      return [spaceData?.main_image];
    }
  })();

  const center = {
    lat: parseFloat(spaceData?.space_latitude.trim()),
    lng: parseFloat(spaceData?.space_logitude.trim()),
    image: imagesArray[0],
    spaceData: spaceData,
  };

  const markers = [center];

  if (spaceData) {
    return (
      <FlexContainer className={'flex-column productPreList'}>
        <FlexContainer className={'width100 margb10'}>
          <FlexContainer className={'flex-column items-align-start width100'}>
            <button
              onClick={closeButton}
              className={'bgWhite bold border-rad8px borderNone grayN250 padtb10'}
            >
              <i className="fas fa-arrow-left" /> Volver
            </button>
          </FlexContainer>
        </FlexContainer>
        <NewSpaceCardDetailVersion
          key={1}
          spaceInfo={spaceData}
          handleDetailButton={goToSpaceDetails}
          priceAndCapacityDetails
        />
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>Amenidades</Label>
        <AmenitiesSection
          wifi={wifi}
          coffee={coffee}
          parking={parking}
          staff={staff}
          cleaning_service={cleaning_service}
          printing_service={printing_service}
          common_areas={common_areas}
          convention_hall={convention_hall}
          snacks={snacks}
          phone_service={phone_service}
        />
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>
          Capacidad Máxima
        </Label>
        <Label type="text-16" className={'width100 margtb20 text-justify'}>
          {spaceData?.host_capacity} Personas
        </Label>
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>
          Horario de operación
        </Label>
        <Label type="text-16" className={'width100 margtb20 text-justify'}>
          09 a 20 horas
        </Label>
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>
          Características de la ubicación
        </Label>
        <Label type="text-16" className={'width100 margtb20 text-justify'}>
          <div dangerouslySetInnerHTML={{ __html: spaceData?.space_details }} />
          <div dangerouslySetInnerHTML={{ __html: spaceData?.space_details_general }} />
        </Label>
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>
          Ubicación del espacio
        </Label>
        <Label type="text-16" className={'width100 margtb20 text-justify'}>
          <div
            dangerouslySetInnerHTML={{
              __html: spaceData?.space_address?.replace(/\d+/g, '****'),
            }}
          />
        </Label>
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>Opiniones</Label>
        <CommentSection
          currentUser={{
            currentUserId: '01a',
            currentUserImg: 'https://ui-avatars.com/api/name=Riya&background=random',
            currentUserProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
            currentUserFullName: 'Riya Negi',
          }}
          commentData={data}
          logIn={{
            onLogin: () => alert('Call login function '),
            signUpLink: 'http://localhost:3001/',
          }}
          placeHolder="Escribe tu comentario..."
          onSubmitAction={({}) => console.log('check submit, ', data)}
          currentData={(data) => {
            console.log('current data', data);
          }}
        />
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>
          Políticas de cancelación
        </Label>
        <Label type="text-16" className={'width100 margtb20 text-justify'}>
          Lee nuestras políticas de cancelación {'->'}
        </Label>
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify d-hide'}>
          Otros sitios similares a este
        </Label>
        <Label type="text-16" className={'width100 margtb20 text-justify d-hide'}>
          Te mostramos sitios cuya tarifa está calculada con estos valores:
        </Label>
        <FlexContainer className={'flex-column d-hide'}>
          <SpacesList spacesData={spacesDataFromBackend} key={2} />
        </FlexContainer>
      </FlexContainer>
    );
  } else {
    return <></>;
  }
};

const NotFoundComponent = (props) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <FlexContainer className={'productPreList margtb100 width100 justify-between'}>
      <Label className={'text-20'}>
        No se encontraron resultados para la búsqueda seleccionada. Inténtelo nuevamente.
      </Label>
      <button
        type="button"
        className={'bgGray050 border-rad4px borderGray050 fontGray500 text-16'}
        onClick={handleBack}
      >
        <span className={'padlr10'} style={{ display: 'inline-flex', gap: '6px' }}>
          <i className={'fa fa-arrow-left margr6'} aria-hidden="true" /> Atrás
        </span>
      </button>
    </FlexContainer>
  );
};

const ProductPreListView = (props) => {
  const { spacesDataFromBackend, searchFormData } = props;
  const [spaceDetailData, setSpaceDetailData] = useState(null);
  const [accordionKey, setAccordionKey] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [mapStyle, setMapStyle] = useState(false);
  const isShowGeneralMap = !spaceDetailData && spacesDataFromBackend.length >= 1;

  const openFiltersModal = () => setModalOpen(true);
  const closeFiltersModal = () => setModalOpen(false);
  const turnMapView = () => setMapStyle(!mapStyle);

  console.log('aqui los espacios: ', spacesDataFromBackend);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log('searchFormData cambió:', searchFormData);
    window.scrollTo(0, 0);
    setAccordionKey(accordionKey + 1);
  }, [searchFormData]);

  const coordinates = searchFormData?.coordinates;

  const openSpaceDetail = (space) => {
    console.log(JSON.stringify(space));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setSpaceDetailData(space);
  };
  const closeSpaceDetail = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setSpaceDetailData(null);
  };

  const containerLeftClass = classNames(
    'flex-column margtb20 m-margtb0 gap-20 format40 m-format100',
    {
      't-hide m-hide': spaceDetailData,
    },
  );
  const containerRightClass = classNames(
    'flex-column margtb20 m-margtb0 padlr20 m-padlr0 t-hide m-hide width100',
    {
      format100: spaceDetailData,
      format100: !spaceDetailData,
    },
  );
  const center = {
    lat: coordinates?.lat,
    lng: coordinates?.lng,
  };
  return (
    <FlexContainer className={'flex-column width100 bgWhite'}>
      <PageContainer className={'margt40 m-margt80'}>
        {!spaceDetailData && (
          <FlexContainer
            className={'flex-row justify-between padlr40 m-padlr10 m-margb20 margt20 m-margt0'}
          >
            <button
              variant="text"
              onClick={openFiltersModal}
              className={'flex-row align-items-center bgWhite borderNone text-16'}
              style={{ color: '#022b54', fontWeight: '500' }}
            >
              <i className="fas fa-sliders-h" style={{ marginRight: '8px' }}></i> Filtrar
            </button>

            <button
              variant="text"
              onClick={turnMapView}
              className={'flex-row align-items-center bgWhite borderNone text-16 d-hide'}
              style={{ color: '#022b54', fontWeight: '500' }}
            >
              <i
                className={mapStyle ? 'fas fa-th' : 'fas fa-map'}
                style={{ marginRight: '8px' }}
              ></i>{' '}
              {mapStyle ? 'Ver en lista' : 'Ver en el mapa'}
            </button>
          </FlexContainer>
        )}
        <Dialog
          open={modalOpen}
          onClose={closeFiltersModal}
          fullScreen
          PaperProps={{
            sx: {
              height: '100vh',
              width: '100vw',
              margin: 0,
              borderRadius: 0,
            },
          }}
        >
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: '60px',
            }}
          >
            <IconButton
              onClick={closeFiltersModal}
              sx={{
                backgroundColor: '#022b54',
                color: 'white',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                '&:hover': {
                  backgroundColor: 'darkblue',
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <span className={'fontGray250 subTitle3'} style={{ paddingLeft: '10px' }}>
              Filtros
            </span>
          </DialogTitle>
          <DialogContent>
            <FlexContainer className="flex-column  padtb20">
              <Label type="text-18 margb30 fontGray300">
                <strong>Amenidades</strong>
              </Label>
              <FlexContainer className="flex-column gap-30">
                {[
                  { label: 'Staff en el sitio', icon: 'fas fa-users' },
                  { label: 'Servicio de limpieza', icon: 'fas fa-broom' },
                  { label: 'Teléfono', icon: 'fas fa-phone' },
                  { label: 'Servicio de impresora', icon: 'fas fa-print' },
                  { label: 'Eventos profesionales', icon: 'fas fa-chalkboard-teacher' },
                  { label: 'Áreas comunes', icon: 'fas fa-building' },
                  { label: 'Salones de convenciones', icon: 'fas fa-chalkboard' },
                  { label: 'Bocadillos', icon: 'fas fa-candy-cane' },
                ].map((amenity, index) => (
                  <FlexContainer
                    key={index}
                    className="align-items-center gap-20"
                    style={{ justifyContent: 'space-between' }}
                  >
                    <FlexContainer className="align-items-center gap-10">
                      <i className={`${amenity.icon} text-20 fontGray250`} />
                      <Label type="text-16 fontGray250">{amenity.label}</Label>
                    </FlexContainer>
                    <input type="checkbox" />
                  </FlexContainer>
                ))}
              </FlexContainer>
            </FlexContainer>
          </DialogContent>
        </Dialog>

        <FlexContainer className={'m-flex-column padlr40 m-padlr10'}>
          {spacesDataFromBackend.length === 0 && <NotFoundComponent />}
          {spacesDataFromBackend.length !== 0 && (
            <>
              <FlexContainer className={containerLeftClass}>
                {!mapStyle && (
                  <>
                    {spacesDataFromBackend?.length >= 1 &&
                      spacesDataFromBackend.map((space, index) => (
                        <NewSpaceCard
                          key={index}
                          spaceInfo={space}
                          //isInlineStyle
                          handleDetailButton={() => openSpaceDetail(space)}
                          priceAndCapacityDetails
                          wifi
                        />
                      ))}
                  </>
                )}
                {mapStyle && (
                  <FlexContainer
                    className={'m-format100 margt20'}
                    style={{
                      minHeight: '300px',
                    }}
                  >
                    {isShowGeneralMap && (
                      <GoogleMapComponent center={center} markers={spacesDataFromBackend} key={2} />
                    )}
                  </FlexContainer>
                )}
              </FlexContainer>
              <FlexContainer className={containerRightClass}>
                {isShowGeneralMap && (
                  <GoogleMapComponent center={center} markers={spacesDataFromBackend} key={1} />
                )}
                {spaceDetailData && (
                  <SpaceDetailInfo
                    closeButton={closeSpaceDetail}
                    spaceData={spaceDetailData}
                    spacesDataFromBackend={spacesDataFromBackend}
                  />
                )}
              </FlexContainer>
            </>
          )}
          {spaceDetailData && (
            <FlexContainer className={'flex-column margtb20 m-margtb0 padlr20 m-padlr0 d-hide'}>
              <SpaceDetailInfo
                closeButton={closeSpaceDetail}
                spaceData={spaceDetailData}
                spacesDataFromBackend={spacesDataFromBackend}
              />
            </FlexContainer>
          )}
        </FlexContainer>
      </PageContainer>
    </FlexContainer>
  );
};

export default ProductPreListView;
