import React, { useState, useEffect } from 'react';

export const useTermsAndConditionsPromo = () => {
  const [isLoading, setIsLoading] = useState(true);
  let spacesData = [
    {
      space_id: 1,
      space_name: 'The Club Condesa',
      space_address:
        'Campeche 410-Piso 1, Colonia Condesa, Cuauhtémoc, 06170 Ciudad de México, CDMX',
      space_title: 'Hermoso Coworking en Condesa',
      main_image: 'spaces/espacio-3.png',
      state_name: 'AVAILABLE',
      space_details:
        'Espacio de reuniones con capacidad para 8 personas, diseñado para maximizar la comodidad y productividad. Cuenta con WiFi de alta velocidad, mobiliario moderno, pantallas LED y pizarra para presentaciones. Además, incluye servicio de cafetería, recepción de visitas y áreas sanitizadas para una experiencia profesional y segura.',
      user_name: 'Mario Enrique',
      user_lastname: 'Vargas Pino',
      type_name: 'HOST',
      house_number: 410,
      space_latitude: '19.400561           ',
      space_logitude: ' -99.170602         ',
      street_reference: 'Casi esquina con Guerrero, a unos metros del metro y metrobús Tlatelolco',
      zip_code: 1233333,
      is_reserved: '0',
      gmaps_url: 'https://maps.app.goo.gl/Wd51Lh7N36nxyXuC9',
      type: 'Coworking',
      price: 427,
    },
    {
      space_id: 2,
      space_name: 'The Club San Ángel',
      space_address:
        'Av. Revolución 1564, Guadalupe Inn, Álvaro Obregón, 01000 Ciudad de México, CDMX',
      space_title: 'Sala de juntas en Álvaro Obregón',
      main_image: 'spaces/espacio-5.png',
      state_name: 'AVAILABLE',
      space_details:
        'Sala de reuniones ideal para grupos de 6 a 8 personas, equipada con WiFi de alta velocidad, televisor y pizarrón para presentaciones efectivas. Incluye servicio de cafetería y recepción de visitas para una experiencia completa. Disfruta de un ambiente cómodo y seguro con aire acondicionado y espacios sanitizados.',
      user_name: 'Mario Enrique',
      user_lastname: 'Vargas Pino',
      type_name: 'HOST',
      house_number: 410,
      space_latitude: '19.400561           ',
      space_logitude: ' -99.170602         ',
      street_reference: 'Casi esquina con Guerrero, a unos metros del metro y metrobús Tlatelolco',
      zip_code: 1233333,
      is_reserved: '0',
      gmaps_url: 'https://maps.app.goo.gl/Wd51Lh7N36nxyXuC9',
      type: 'Coworking',
      price: 250,
    },
    {
      space_id: 3,
      space_name: 'Orbita CoWorks',
      space_address: 'C. Ignacio Pérez Sur 28, El Carrizal, 76030 Santiago de Querétaro, Qro.',
      space_title: 'Sala de juntas amplia y luminosa en Querétaro',
      main_image: 'spaces/espacio-4.png',
      state_name: 'AVAILABLE',
      space_details:
        'Ubicados en el corazón de Querétaro, es el espacio preferido para el coworking, ofreciendo un ambiente moderno y flexible ideal para emprendedores y empresas en crecimiento. Espacios de Coworking: Escritorios en áreas abiertas con techos altos y moderna vegetación interior, diseñados para fomentar la productividad. Salas de Juntas: Acceso a cuatro salas completamente equipadas para 6 a 26 personas, perfectas para tus reuniones. Lounge Recreativo: Un área de descanso acogedora, equipada con mobiliario moderno y ergonómico, ideal para relajarte o colaborar. Snack Bar: Disfruta de un refrigerio entre tus actividades. Eventos y Networking: Participa en actividades exclusivas que enriquecen tu experiencia laboral y fomentan el intercambio de ideas. Además, contribuimos al medio ambiente utilizando energía renovable a través de paneles solares, promoviendo un consumo responsable entre nuestros usuarios.',
      user_name: 'Mario Enrique',
      user_lastname: 'Vargas Pino',
      type_name: 'HOST',
      house_number: 410,
      space_latitude: '19.400561           ',
      space_logitude: ' -99.170602         ',
      street_reference: 'Casi esquina con Guerrero, a unos metros del metro y metrobús Tlatelolco',
      zip_code: 1233333,
      is_reserved: '0',
      gmaps_url: 'https://maps.app.goo.gl/Wd51Lh7N36nxyXuC9',
      type: 'Sala de Juntas',
      price: 500,
    },
  ];

  useEffect(() => {
    if (spacesData.length >= 1) {
      setIsLoading(false);
    }
  }, [spacesData]);

  return {
    isLoading,
    setIsLoading,
    spacesData,
  };
};
