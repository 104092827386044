import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import GeneralSearchForm from '@/components/general/Forms/GeneralSearchForm/GeneralSearchForm';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import classNames from 'classnames';

const SearchAccordion = (props) => {
  const { className } = props;
  const searchFormData = useSelector((state) => state.searchFormData);
  const [inputValue, setInputValue] = useState(searchFormData?.searchFormData?.description || null);
  const [suggestions, setSuggestions] = useState([]);
  const autocompleteService = useRef(null);
  const [renderKey, setRenderKey] = useState(1);

  useEffect(() => {
    const initializeAutocomplete = () => {
      if (!autocompleteService.current && window.google) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      }
    };

    if (!window.google) {
      window.addEventListener('load', initializeAutocomplete);
      return () => window.removeEventListener('load', initializeAutocomplete);
    } else {
      initializeAutocomplete();
    }
  }, []);

  const handleInputChange = (e) => {
    const searchValue = e.target.value;
    setInputValue(searchValue);
    setRenderKey(renderKey + 1);

    if (searchValue.length > 3 && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions(
        { input: searchValue },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSuggestions(predictions);
          }
        },
      );
    }
  };
  const containerClass = classNames('flex-column width100 pos-fixed', className);
  return (
    <FlexContainer className={containerClass} style={{ zIndex: 2 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <TuneIcon />
          <span className={'margl10 padt4'}> Filtrar</span>
        </AccordionSummary>
        <AccordionDetails>
          <FlexContainer className={'flex-column sliderInfo-box-input marglr40 m-marglr20'}>
            <input
              type="text"
              value={inputValue}
              className={'borderRadius6-7 bgGray0 borderGray100 padtb20 text-16 text-center'}
              placeholder={'Busca aquí el espacio de tu preferencia'}
              onChange={handleInputChange}
            />
          </FlexContainer>
          <GeneralSearchForm
            inputValue={inputValue}
            externalSelectedSuggestion={searchFormData?.selectedSuggestion}
            className={'marglr40 m-marglr20 margt20'}
            suggestions={suggestions}
            key={renderKey}
          />
        </AccordionDetails>
      </Accordion>
    </FlexContainer>
  );
};

export default SearchAccordion;
