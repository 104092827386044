import { useQuery, useMutation } from '@tanstack/react-query';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchAllReservations = async () => {
  const response = await fetch(`${apiUrl}/GET/reservations`);
  if (!response.ok) {
    throw new Error('Error fetching reservations');
  }
  return response.json();
};

export const fetchReservationById = async id => {
  const response = await fetch(`${apiUrl}/GET/reservations/${id}`);
  if (!response.ok) {
    throw new Error('Error fetching reservation by ID');
  }
  return response.json();
};

export const createReservation = async reservationData => {
  const response = await fetch(`${apiUrl}/POST/reservations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reservationData),
  });

  if (!response.ok) {
    throw new Error('Error creating reservation');
  }
  return response.json();
};

export const updateReservation = async ({ id, reservationData }) => {
  const response = await fetch(`${apiUrl}/PUT/reservations/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reservationData),
  });
  if (!response.ok) {
    throw new Error('Error updating reservation');
  }
  return response.json();
};

export const deleteReservation = async id => {
  const response = await fetch(`${apiUrl}/PUT/delete_reservations/${id}`, {
    method: 'PUT',
  });

  if (!response.ok) {
    throw new Error('Error deleting reservation');
  }
  return response.json();
};

export const deleteReservationsSeveral = async reservationIds => {
  const response = await fetch(`${apiUrl}/PUT/delete_reservations_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ reservationIds }),
  });

  if (!response.ok) {
    throw new Error('Error deleting multiple reservations');
  }
  return response.json();
};

export const enabledReservationsSeveral = async reservationIds => {
  const response = await fetch(`${apiUrl}/PUT/enabled_reservations_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ reservationIds }),
  });

  if (!response.ok) {
    throw new Error('Error enabling multiple reservations');
  }
  return response.json();
};

export const disabledReservationsSeveral = async reservationIds => {
  const response = await fetch(`${apiUrl}/PUT/disabled_reservations_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ reservationIds }),
  });

  if (!response.ok) {
    throw new Error('Error disabling multiple reservations');
  }
  return response.json();
};

export const useFetchAllReservations = () => {
  return useQuery({
    queryKey: ['reservations'],
    queryFn: fetchAllReservations,
  });
};

export const useFetchReservationById = id => {
  return useQuery({
    queryKey: ['reservation', id],
    queryFn: () => fetchReservationById(id),
    enabled: !!id,
  });
};

export const useCreateReservation = () => {
  return useMutation({
    mutationFn: createReservation,
    onSuccess: () => {
      console.log('Reservation created successfully');
    },
    onError: error => {
      console.error('Error creating reservation:', error);
    },
  });
};

export const useUpdateReservation = () => {
  return useMutation({
    mutationFn: updateReservation,
    onSuccess: () => {
      console.log('Reservation updated successfully');
    },
    onError: error => {
      console.error('Error updating reservation:', error);
    },
  });
};

export const useDeleteReservation = () => {
  return useMutation({
    mutationFn: deleteReservation,
    onSuccess: () => {
      console.log('Reservation deleted successfully');
    },
    onError: error => {
      console.error('Error deleting reservation:', error);
    },
  });
};

export const useDeleteReservationsSeveral = () => {
  return useMutation({
    mutationFn: deleteReservationsSeveral,
    onSuccess: () => {
      console.log('Multiple reservations deleted successfully');
    },
    onError: error => {
      console.error('Error deleting multiple reservations:', error);
    },
  });
};

export const useEnabledReservationsSeveral = () => {
  return useMutation({
    mutationFn: enabledReservationsSeveral,
    onSuccess: () => {
      console.log('Multiple reservations enabled successfully');
    },
    onError: error => {
      console.error('Error enabling multiple reservations:', error);
    },
  });
};

export const useDisabledReservationsSeveral = () => {
  return useMutation({
    mutationFn: disabledReservationsSeveral,
    onSuccess: () => {
      console.log('Multiple reservations disabled successfully');
    },
    onError: error => {
      console.error('Error disabling multiple reservations:', error);
    },
  });
};
