import React, { useState, useEffect, useRef } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import {
  TextField,
  Button,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Label from '@/components/form/Label/Label';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { setSearchFormData } from '@/redux/actions/searchFormDataActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './NewGeneralSearchForm.scss';

const fetchUserLocation = async () => {
  try {
    const response = await fetch('http://ip-api.com/json/');
    if (!response.ok) throw new Error(`Error: ${response.status}`);
    const data = await response.json();
    return { lat: data.lat, lng: data.lon };
  } catch (error) {
    console.error('Error fetching location:', error);
    return null;
  }
};

const validationSchema = Yup.object().shape({
  location: Yup.string().required('El lugar es obligatorio'),
  startDate: Yup.date().required('La fecha es obligatoria'),
  //endTime: Yup.string().required('El horario es obligatorio'),
  guests: Yup.number()
    .min(1, 'Debe haber al menos 1 persona')
    .required('Cantidad de personas requerida'),
});

const LocationSuggestions = (props) => {
  const { suggestions, setLocationToSearch } = props;
  return (
    <FlexContainer className={'flex-column width100'}>
      {suggestions?.map((suggestion) => (
        <FlexContainer
          className={'fontGray400 grayShadow16 padlr10 padtb20 text-14 pointer'}
          key={suggestion.place_id}
          onClick={() => setLocationToSearch(suggestion)}
        >
          {suggestion.description}
        </FlexContainer>
      ))}
    </FlexContainer>
  );
};

const NewGeneralSearchForm = (props) => {
  const { searchType } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [activeField, setActiveField] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const autocompleteService = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const initializeAutocomplete = () => {
      if (!autocompleteService.current && window.google) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      }
    };

    fetchUserLocation().then((location) => setUserLocation(location));

    if (!window.google) {
      window.addEventListener('load', initializeAutocomplete);
      return () => window.removeEventListener('load', initializeAutocomplete);
    } else {
      initializeAutocomplete();
    }
  }, []);

  useEffect(() => {
    if (modalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [modalOpen]);

  const initialValues = {
    location: '',
    startDate: '',
    endTime: '',
    guests: 1,
  };

  const mapFieldLabel = (field) => {
    const fieldMap = {
      location: 'el lugar',
      startDate: 'la fecha',
      endTime: 'horario',
      guests: 'número de personas',
    };

    return fieldMap[field] || 'Campo no encontrado';
  };

  const TitleLabel = () => {
    if (searchType === 'Sala') {
      return (
        <Label className={'subTitle2 text-11 m-text-20 fontGray250 margt12 margb10 text-center'}>
          ENCUENTRA TU <br />
          SALA DE JUNTAS
        </Label>
      );
    } else if (searchType === 'Coworking') {
      return (
        <Label className={'subTitle2 text-11 m-text-20 fontGray250 margt12 margb10 text-center'}>
          ENCUENTRA TU <br />
          PASE DE COWORKING
        </Label>
      );
    } else {
      return <></>;
    }
  };

  const handleSubmit = (values) => {
    console.log('Datos enviados:', values);
    values.endTime = null;
    const spaceType = searchType === 'Sala' ? 'sala de juntas' : 'coworking';
    const searchFormData = { ...values, coordinates, selectedSuggestion, spaceType };
    dispatch(setSearchFormData(searchFormData));
    navigate('/productPreList');
  };

  return (
    <FlexContainer
      className={
        'flex-column format100 m-formatAuto bgWhite m-margt20 m-margb50 m-marglr10 padtb20'
      }
    >
      <TitleLabel />
      <FlexContainer className={'flex-column gap-18 format100 padtb20'}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue }) => {
            const handleLocationChange = (e) => {
              const searchValue = e.target.value;
              if (searchValue.length > 3 && autocompleteService.current) {
                if (userLocation) {
                  autocompleteService.current.getPlacePredictions(
                    {
                      input: searchValue,
                      location: new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
                      radius: 50000,
                    },
                    (predictions, status) => {
                      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        setSuggestions(predictions);
                      }
                    },
                  );
                } else {
                  autocompleteService.current.getPlacePredictions(
                    { input: searchValue },
                    (predictions, status) => {
                      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        setSuggestions(predictions);
                      }
                    },
                  );
                }
              }
              setSelectedSuggestion(null);
              setFieldValue('location', searchValue);
            };

            const setSelectedSuggestionxForm = (value) => {
              setSelectedSuggestion(value);
              if (value?.place_id && window.google) {
                const service = new window.google.maps.places.PlacesService(
                  document.createElement('div'),
                );
                service.getDetails({ placeId: value.place_id }, (place, status) => {
                  if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    const newCoordinates = place.geometry.location.toJSON();
                    setCoordinates(newCoordinates);
                  }
                });
              }
              setFieldValue('location', value?.description);
            };

            const increaseGuests = () => setFieldValue('guests', values.guests + 1);
            const decreaseGuests = () => {
              if (values.guests > 1) {
                setFieldValue('guests', values.guests - 1);
              }
            };

            const disabledButton = activeField === 'location' && !selectedSuggestion;
            const selectFieldButtonClass = disabledButton
              ? 'm-format100 padtb10 text-18 margtb40 border-rad18px bgNeutral075 fontWhite'
              : 'm-format100 padtb10 text-18 margtb40 border-rad18px bgBlue400 fontWhite';

            const handleOpenModal = (field) => {
              setActiveField(field);
              setModalOpen(true);
            };

            const handleCloseModal = () => {
              setModalOpen(false);
              if (disabledButton) {
                setSelectedSuggestion(null);
                setFieldValue('location', '');
              }
              setActiveField('');
            };

            return (
              <Form>
                <FlexContainer
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  <TextField
                    fullWidth
                    label="¿A dónde vas a ir a trabajar?"
                    variant="outlined"
                    value={values.location}
                    onClick={() => handleOpenModal('location')}
                    error={touched.location && Boolean(errors.location)} // Activa el borde rojo si hay error
                    helperText={touched.location && errors.location} // Muestra el mensaje de error
                    InputLabelProps={{
                      sx: { fontSize: '18px', fontWeight: '500' },
                    }}
                    InputProps={{
                      sx: {
                        '& .MuiOutlinedInput-notchedOutline': {
                          legend: {
                            width: 'auto',
                            padding: '0 32px',
                          },
                        },
                      },
                      style: { fontSize: '12px' },
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOnOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    fullWidth
                    label="¿Cuándo?"
                    variant="outlined"
                    value={values.startDate}
                    onClick={() => handleOpenModal('startDate')}
                    error={touched.startDate && Boolean(errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                    InputLabelProps={{
                      sx: { fontSize: '18px', fontWeight: '500' },
                      shrink: true,
                    }}
                    InputProps={{
                      style: { fontSize: '14px' },
                      sx: {
                        '& .MuiOutlinedInput-notchedOutline': {
                          legend: {
                            width: 'auto',
                            padding: '0 10px',
                          },
                        },
                      },
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarTodayOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  {searchType === 'Sala' && (
                    <TextField
                      fullWidth
                      label="¿Qué horario?"
                      variant="outlined"
                      value={values.endTime}
                      onClick={() => handleOpenModal('endTime')}
                      error={touched.endTime && Boolean(errors.endTime)}
                      helperText={touched.endTime && errors.endTime}
                      InputLabelProps={{
                        sx: {
                          fontSize: '18px',
                          fontWeight: '500',
                        },
                        shrink: true,
                      }}
                      InputProps={{
                        style: { fontSize: '14px' },
                        sx: {
                          '& .MuiOutlinedInput-notchedOutline': {
                            legend: {
                              width: 'auto',
                              padding: '0 10px',
                            },
                          },
                        },
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccessTimeOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}

                  <TextField
                    fullWidth
                    label="¿Cuántas personas?"
                    variant="outlined"
                    type="number"
                    value={values.guests}
                    onClick={() => handleOpenModal('guests')}
                    error={touched.guests && Boolean(errors.guests)}
                    helperText={touched.guests && errors.guests}
                    InputLabelProps={{
                      sx: { fontSize: '18px', fontWeight: '500' },
                    }}
                    InputProps={{
                      sx: {
                        '& .MuiOutlinedInput-notchedOutline': {
                          legend: {
                            width: 'auto',
                            padding: '0 14px',
                          },
                        },
                      },
                      style: { fontSize: '14px' },
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlineOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <button
                    className={
                      'm-format100 bgBlue400 border-rad16px borderGray050 fontWhite text-16 grayShadow16 margt10 margb20 padlr40 padtb10'
                    }
                    type="submit"
                  >
                    Buscar
                  </button>
                </FlexContainer>

                <Dialog
                  open={modalOpen}
                  onClose={handleCloseModal}
                  fullScreen
                  PaperProps={{
                    sx: {
                      position: 'fixed',
                      height: '100vh',
                      width: '100vw',
                      margin: 0,
                      borderRadius: 0,
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      overflow: 'hidden',
                    },
                  }}
                >
                  <DialogTitle
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '60px',
                    }}
                  >
                    <IconButton
                      onClick={handleCloseModal}
                      sx={{
                        backgroundColor: '#022b54',
                        color: 'white',
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        '&:hover': {
                          backgroundColor: 'darkblue',
                        },
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <span className={'fontGray250 subTitle3'} style={{ paddingLeft: '10px' }}>
                      Selecciona {mapFieldLabel(activeField)}
                    </span>
                    <div />
                  </DialogTitle>
                  <DialogContent>
                    {activeField === 'location' && (
                      <>
                        <TextField
                          fullWidth
                          value={values[activeField]}
                          onChange={(e) => handleLocationChange(e)}
                          type={'text'}
                          InputProps={{
                            style: { fontSize: '14px' },
                          }}
                          autoFocus
                        />
                        <LocationSuggestions
                          suggestions={suggestions}
                          setLocationToSearch={setSelectedSuggestionxForm}
                        />
                      </>
                    )}
                    {activeField === 'guests' && (
                      <FlexContainer className={'gap-10'}>
                        <FlexContainer className={'format50'}>Personas</FlexContainer>
                        <FlexContainer className={'format50'}>
                          <Button variant="outlined" onClick={decreaseGuests}>
                            -
                          </Button>
                          <TextField
                            value={values[activeField]}
                            variant="outlined"
                            type="number"
                            onChange={(e) => setFieldValue(activeField, e.target.value)}
                            inputProps={{ min: 1, readOnly: true, fontSize: '14px' }}
                          />
                          <Button variant="outlined" onClick={increaseGuests}>
                            +
                          </Button>
                        </FlexContainer>
                      </FlexContainer>
                    )}

                    {activeField === 'startDate' && (
                      <FlexContainer className="flex-column align-center formatAuto">
                        <TextField
                          type="date"
                          value={values.startDate}
                          onChange={(e) => setFieldValue('startDate', e.target.value)}
                          autoFocus
                        />
                      </FlexContainer>
                    )}

                    {activeField === 'endTime' && (
                      <FlexContainer className="flex-column align-center formatAuto">
                        <TextField
                          type="time"
                          value={values.endTime}
                          onChange={(e) => {
                            const inputTime = e.target.value;
                            const [hours, minutes] = inputTime.split(':');

                            if (minutes !== '00' && minutes !== '30') return;

                            setFieldValue('endTime', inputTime);
                          }}
                          inputProps={{
                            step: 1800,
                            min: '08:00',
                            max: '22:30',
                          }}
                          autoFocus
                        />
                      </FlexContainer>
                    )}

                    <button
                      className={selectFieldButtonClass}
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={disabledButton}
                      onClick={handleCloseModal}
                    >
                      Seleccionar
                    </button>
                  </DialogContent>
                </Dialog>
              </Form>
            );
          }}
        </Formik>
      </FlexContainer>
    </FlexContainer>
  );
};

export default NewGeneralSearchForm;
