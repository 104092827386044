import React from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import Label from '@/components/form/Label/Label';

const BlogEntry = (props) => {
  const { entryData, setDetailEntryData } = props;
  return (
    <FlexContainer className={'flex-column width100'} onClick={() => setDetailEntryData(entryData)}>
      <FlexContainer className={'width100 bgBlue400 height-220px'}>
        <img
          src={entryData?.entryImage}
          alt="Foto 1"
          style={{ width: '100%', height: '220px' }}
          className={''}
        />
      </FlexContainer>
      <FlexContainer className={'flex-column width100 height-220px'}>
        <Label className="text-12 margtb14">{entryData?.entryRegDate}</Label>
        <Label className="text-18 margb40 margt10 bold">
          <strong>{entryData?.entryTitle}</strong>
        </Label>
        <Label className="text-16 margb20">{entryData?.entryDescription}</Label>
      </FlexContainer>
    </FlexContainer>
  );
};

const EntriesRow = (props) => {
  const { entriesData, setDetailEntryData } = props;
  return (
    <FlexContainer className={'flex-column margtb30 m-margtb20'}>
      <FlexContainer className={'m-flex-column padlr20 m-padlr0 gap-10'}>
        {entriesData.map((entry, index) => (
          <BlogEntry key={index} entryData={entry} setDetailEntryData={setDetailEntryData} />
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

const BlogModule = (props) => {
  const { customTitle } = props;
  const setDetailEntryData = (entryData) => {};

  const spacesCatEntriesData = [
    {
      entryTitle: 'Beneficios de trabajar en espacios de coworking',
      entryDescription:
        'Descubre cómo los espacios de coworking fomentan la creatividad, la colaboración y aumentan la productividad en entornos flexibles.',
      entryImage: 'spaces/slider1.jpg',
      entryRegDate: '15.06.24',
      longDescription: `
        <p>Los espacios de coworking han revolucionado la manera en la que profesionales independientes, startups y empresas consolidadas trabajan día a día. A través de un entorno compartido, se promueve la <strong>colaboración</strong> y se eliminan las distracciones propias del trabajo en casa.</p>
        <p>Entre los principales beneficios de trabajar en un coworking se encuentran:</p>
        <ul>
          <li><strong>Networking:</strong> Al interactuar con otros profesionales, se generan conexiones valiosas para futuros proyectos y oportunidades laborales.</li>
          <li><strong>Flexibilidad:</strong> Los coworkings ofrecen horarios adaptados a las necesidades del trabajador moderno, permitiendo mayor libertad.</li>
          <li><strong>Entorno Inspirador:</strong> Estas instalaciones están diseñadas para estimular la creatividad y productividad.</li>
          <li><strong>Costos Accesibles:</strong> A comparación de rentar oficinas privadas, un espacio compartido es más económico.</li>
        </ul>
        <p>Estudios recientes también muestran cómo este modelo impacta positivamente en la salud mental, reduciendo el aislamiento y generando un sentimiento de comunidad.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.wework.com">WeWork: El futuro del trabajo</a></li>
          <li><a href="https://www.coworkingresources.org">Coworking Resources: Beneficios de coworking</a></li>
          <li><a href="https://www.forbes.com">Forbes: Impacto del coworking en empresas modernas</a></li>
        </ul>
      `,
    },
    {
      entryTitle: 'Top 5 espacios de coworking en tu ciudad',
      entryDescription:
        'Conoce los mejores espacios de coworking con instalaciones modernas, buena ubicación y ambiente profesional.',
      entryImage: 'spaces/slider2.jpg',
      entryRegDate: '20.06.24',
      longDescription: `
        <p>Explorar los mejores espacios de coworking te permite encontrar opciones que se ajusten a tu estilo de trabajo y necesidades.</p>
        <p>Aquí te presentamos los <strong>Top 5 espacios de coworking</strong> en tu ciudad, seleccionados por sus instalaciones modernas, servicios y ubicación estratégica:</p>
        <ol>
          <li><strong>HubWork Central:</strong> Ofrece salas de reuniones, internet de alta velocidad y áreas de descanso.</li>
          <li><strong>SpaceFlex:</strong> Ideal para freelancers, con planes económicos y opciones 24/7.</li>
          <li><strong>WorkHive:</strong> Cuenta con servicios premium como cabinas privadas y gimnasio incluido.</li>
          <li><strong>CollaborateHub:</strong> Espacio moderno que incentiva la creatividad con diseños innovadores.</li>
          <li><strong>OpenDesk Loft:</strong> Perfecto para equipos pequeños y startups en crecimiento.</li>
        </ol>
        <p>Al elegir un coworking, evalúa aspectos como la accesibilidad, el ambiente profesional y los servicios que ofrecen.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.coworking.com">Coworking.com: Directorio de espacios</a></li>
          <li><a href="https://www.thehive.com">The Hive: Espacios innovadores</a></li>
          <li><a href="https://www.remote.co">Remote.co: Mejores coworkings del mundo</a></li>
        </ul>
      `,
    },
    {
      entryTitle: 'Coworking vs Trabajo Remoto: ¿Cuál es la mejor opción?',
      entryDescription:
        'Analizamos las diferencias entre trabajar desde casa y hacerlo en un espacio de coworking para optimizar tu rendimiento laboral.',
      entryImage: 'spaces/slider3.jpg',
      entryRegDate: '25.06.24',
      longDescription: `
        <p>En el debate entre <strong>trabajo remoto</strong> y <strong>espacios de coworking</strong>, cada opción tiene ventajas y desventajas.</p>
        <h5>Trabajo Remoto</h5>
        <p>Trabajar desde casa es cómodo y económico, pero puede generar <em>aislamiento</em> y menor productividad debido a las distracciones del hogar.</p>
        <h5>Espacios de Coworking</h5>
        <p>Los coworkings ofrecen:</p>
        <ul>
          <li>Un <strong>ambiente profesional</strong> que mejora el enfoque.</li>
          <li>Posibilidades de <strong>networking</strong> con otros profesionales.</li>
          <li>Instalaciones como internet de alta velocidad y salas de reuniones.</li>
        </ul>
        <p>La mejor opción dependerá de tus necesidades y estilo de trabajo. Si buscas productividad y colaboración, un coworking es ideal.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.cnbc.com">CNBC: Coworking frente al teletrabajo</a></li>
          <li><a href="https://www.bbc.com">BBC: ¿Espacios de coworking o casa?</a></li>
          <li><a href="https://www.fastcompany.com">FastCompany: Eficiencia en coworking</a></li>
        </ul>
      `,
    },
  ];

  return (
    <FlexContainer className={'flex-column padlr120 m-padlr20 bgWhite'}>
      {customTitle && (
        <Label type="Subtitle-1 text-20 bold padtb20 m-margtb20 padlr20 m-padlr0 fontGray250 text-center">
          {customTitle}
        </Label>
      )}
      <EntriesRow entriesData={spacesCatEntriesData} setDetailEntryData={setDetailEntryData} />
    </FlexContainer>
  );
};

export default BlogModule;
