import React, { useEffect } from 'react';
import { usePromoLanding } from './hooks/PromoLanding.hook';
import PromoLandingView from './views/PromoLandingView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const PromoLandingController = () => {
  const { isLoading, spacesDataFromBackend } = usePromoLanding();
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return <PromoLandingView spacesDataFromBackend={spacesDataFromBackend} />;
  }
};

export default PromoLandingController;
