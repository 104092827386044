import React, { useEffect } from 'react';
import {
  useReservationSuccess,
  useReservationbyID,
} from './hooks/ReservationSuccess.hook';
import { useLocation, useParams } from 'react-router-dom';
import ReservationSuccessView from './views/ReservationSuccessView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const ReservationSuccessController = () => {
  const { isLoading, spacesData } = useReservationSuccess();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transactionId = searchParams.get('transaction_id');
  const { spaceDetail, formValues } = location.state || {};
  const {
    reservation,
    loading: isReservationLoading,
    error,
  } = useReservationbyID(transactionId);

  useEffect(() => {
    if (error) {
      console.log('Error al cargar la reserva:', error);
    }
  }, [error]);

  if (isLoading || isReservationLoading) {
    console.log('Cargando...');
    return <LoadingComponent loading={isLoading} />;
  }

  // Una vez cargados los datos, mostrar la vista de éxito
  console.log('Spaces Data:', spacesData);
  console.log('Reservation:', reservation);

  return (
    <ReservationSuccessView
      spacesData={spacesData}
      spaceDetail={spaceDetail}
      formValues={formValues}
      reservation={reservation}
    />
  );
};

export default ReservationSuccessController;
