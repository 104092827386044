import React, { useState, useEffect } from 'react';
import { fetchReservationById } from '@/services/reservationService';

export const useReservationSuccess = () => {
  const [isLoading, setIsLoading] = useState(true);
  let spacesData = [{}, {}, {}];

  useEffect(() => {
    if (spacesData.length >= 1) {
      setIsLoading(false);
    }
  }, [spacesData]);

  return {
    isLoading,
    setIsLoading,
    spacesData,
  };
};

export const useReservationbyID = id => {
  const [reservation, setReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);

  useEffect(() => {
    if (!id) return;
    const getReservation = async () => {
      try {
        const data = await fetchReservationById(id);
        setReservation(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    getReservation();
  }, [id]);
  return {
    reservation,
    loading,
    error,
  };
};
