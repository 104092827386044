import React, { useState, useEffect } from 'react';
import SpacesList from '@/components/modules/SpacesList/SpacesList';
import OurClients from '@/components/modules/OurClients/OurClients';
import BlogModule from '@/components/modules/BlogModule/BlogModule';
import HowItWorks from '@/components/modules/HowItWorks/HowItWorks';
import NewHomeSlider from '../components/NewHomeSlider/NewHomeSlider';
import HomeFilters from '../components/HomeFilters/HomeFilters';
import CustomerExperience from '../components/CustomerExperience/CustomerExperience';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import NewGeneralSearchForm from '@/components/general/Forms/NewGeneralSearchForm/NewGeneralSearchForm';

const HomeView = (props) => {
  const { spacesDataFromBackend } = props;
  const [isVisibleNewForm, setIsVisibleNewForm] = useState(false);
  const [searchType, setSearchType] = useState('');

  const showNewForm = (type) => {
    window.scrollTo(0, 0);
    setIsVisibleNewForm(true);
    setSearchType(type);
  };

  const hideNewForm = () => {
    window.scrollTo(0, 0);
    setIsVisibleNewForm(false);
    setSearchType('');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const firstSequence = [
    'RENTA UNA SALA DE JUNTAS',
    1000,
    'RENTA UNA SALA DE JUNTAS',
    1000,
    'RENTA UNA SALA DE JUNTAS',
    1000,
    'RENTA UNA SALA DE JUNTAS',
    1000,
  ];

  const secondSequence = [
    'RENTA UN ESPACIO DE TRABAJO',
    1000,
    'RENTA UN ESPACIO DE TRABAJO',
    1000,
    'RENTA UN ESPACIO DE TRABAJO',
    1000,
    'RENTA UN ESPACIO DE TRABAJO',
    1000,
  ];

  const firstImagesArray = ['spaces/juntas.jpg', 'spaces/juntas.jpg'];
  const secondImagesArray = ['spaces/cowork.jpg', 'spaces/cowork.jpg'];

  return (
    <div className="App">
      <FlexContainer className={isVisibleNewForm ? 'bgWhite m-flex-column' : 'm-flex-column'}>
        {isVisibleNewForm && (
          <FlexContainer className={'flex-column m-margt120 format100 m-formatAuto'}>
            <button
              type="button"
              className={
                'bgWhite border-rad4px padlr20 marglr10 padtb6 borderGray050 fontGray500 text-16 m-margt40'
              }
              onClick={() => hideNewForm()}
            >
              <i className={'fa fa-arrow-left margr6'} aria-hidden="true" />
              Atrás
            </button>
            <NewGeneralSearchForm searchType={searchType} />
          </FlexContainer>
        )}
        {!isVisibleNewForm && (
          <>
            <NewHomeSlider
              spacesData={spacesDataFromBackend}
              personalizedContainerClass={'format50 m-format100 m-margt70'}
              personalizedPlaceholder={'Buscar una sala de juntas'}
              sequence={firstSequence}
              imagesArray={firstImagesArray}
              showNewForm={() => showNewForm('Sala')}
            />
            <NewHomeSlider
              spacesData={spacesDataFromBackend}
              personalizedContainerClass={'format50 m-format100'}
              personalizedPlaceholder={'Buscar un coworking'}
              sequence={secondSequence}
              imagesArray={secondImagesArray}
              showNewForm={() => showNewForm('Coworking')}
            />
          </>
        )}
      </FlexContainer>
      {!isVisibleNewForm && <HomeFilters />}
      <SpacesList spacesData={spacesDataFromBackend} key={1} />
      <OurClients customTitle={'Ellos Confían en Nosotros'} />
      <BlogModule customTitle={'Blog de Popnest'} />
      <HowItWorks
        customTitle={'¿Cómo funciona nuestro servicio?'}
        buttonTitle={'Revisa Nuestras Preguntas Frecuentes'}
        isShowButton
      />
      <CustomerExperience />
    </div>
  );
};

export default HomeView;
