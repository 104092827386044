import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import Label from '@/components/form/Label/Label';
import './TermsAndConditionsPromo.scss';

const TermsAndConditions = (props) => {
  const { spacesData } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageContainer className={'termsView flex-column margtb80 text-justify'}>
      <Label className={'text-12 m-text-10'}>
        <h1 className={'Subtitle-1 text-26'}>Términos y Condiciones de la Promoción de navidad</h1>
        <div class="section margt10">
          <h1 className={'Subtitle-1 text-22'}>1. Información General de la Promoción</h1>
          <p>
            <strong>1.1 Compra Disponible:</strong> La compra está disponible únicamente desde el 15
            de diciembre hasta el 31 de diciembre de 2024.
          </p>
          <p>
            <strong>1.2 Duración de la Promoción:</strong> Los servicios adquiridos podrán ser
            utilizados hasta un máximo de 8 horas en horario laboral durante el periodo de uso
            válido.
          </p>
          <p>
            <strong>1.3 Espacios Disponibles:</strong> La promoción cubre el uso de escritorios,
            áreas comunes, tanto interiores como exteriores de los espacios de Popnest.
          </p>
          <p>
            <strong>1.4 Servicios Incluidos:</strong> Los usuarios tendrán acceso a un coffee break
            y un concierge personalizado durante el uso del espacio.
          </p>
          <p>
            <strong>1.5 Uso Válido:</strong> La compra realizada será válida para su uso entre el 1
            de enero y el 31 de marzo de 2025 (3 meses).
          </p>
          <p>
            <strong>1.6 Fecha de Finalización de la Promoción:</strong> Esta promoción finalizará el
            31 de diciembre de 2024.
          </p>

          <h1 className={'Subtitle-1 text-22'}>2. Condiciones de Compra y Uso</h1>
          <p>
            <strong>2.1 Reembolsos y Cancelaciones:</strong> No se permitirán reembolsos ni
            cancelaciones una vez realizada la compra.
          </p>
          <p>
            <strong>2.2 Transferencia de Pases:</strong> Los pases comprados pueden ser transferidos
            a otra persona. Para realizar este cambio, el usuario deberá contactar al equipo de
            Popnest.
          </p>
          <p>
            <strong>2.3 Disponibilidad de Espacios:</strong> La disponibilidad de los espacios está
            sujeta a confirmación previa por parte de Popnest. Los usuarios deberán esperar la
            confirmación antes de finalizar la reserva.
          </p>

          <h1 className={'Subtitle-1 text-22'}>3. Proceso de Reservación</h1>
          <p>
            <strong>3.1 Reserva de Espacios:</strong> Para hacer efectiva la reservación, el usuario
            deberá comunicarse con Popnest a través del correo electrónico{' '}
            <a href="mailto:info@popnest.org">info@popnest.org</a> o por WhatsApp a los números
            5572222021 o 7131302487. El equipo de Popnest se encargará de gestionar y coordinar la
            reservación según los detalles proporcionados por el usuario.
          </p>
          <p>
            <strong>3.2 En Caso de No Disponibilidad:</strong> Si no hay disponibilidad en la fecha
            o ubicación seleccionada, no se realizarán reembolsos. Popnest proporcionará una lista
            de los espacios y horarios disponibles para que el usuario pueda elegir uno de ellos y
            realizar su reservación.
          </p>
          <p>
            <strong>3.3 Medios de Comunicación:</strong> En caso de que el equipo de Popnest no
            pueda atender la solicitud a través de WhatsApp, el usuario deberá utilizar el correo
            electrónico como vía de comunicación. El usuario está obligado a intentar ambos medios
            de contacto para comunicarse con Popnest.
          </p>

          <h1 className={'Subtitle-1 text-22'}>4. Responsabilidad de Popnest</h1>
          <p>
            <strong>4.1 Gestión y Coordinación de la Reservación:</strong> Popnest será responsable
            de gestionar y coordinar la reservación. Sin embargo, en caso de cualquier inconveniente
            durante el trayecto o la estancia en el espacio, Popnest no se hará responsable de los
            incidentes, daños o situaciones imprevistas que puedan surgir durante el uso del espacio
            reservado.
          </p>

          <h1 className={'Subtitle-1 text-22'}>5. Vigencia de la Promoción</h1>
          <p>
            <strong>5.1 Vigencia de Uso:</strong> Los servicios adquiridos serán válidos para su uso
            entre el 1 de enero y el 31 de marzo de 2025. Cualquier uso posterior a esta fecha no
            estará cubierto por esta promoción.
          </p>

          <h1 className={'Subtitle-1 text-22'}>6. Disposiciones Generales</h1>
          <p>
            <strong>6.1 Modificaciones a los Términos y Condiciones:</strong> Popnest se reserva el
            derecho de modificar o actualizar estos términos y condiciones en cualquier momento.
            Cualquier cambio será notificado a los usuarios de manera oportuna.
          </p>
          <p>
            <strong>6.2 Aceptación de los Términos:</strong> Al realizar la compra de la promoción,
            el usuario acepta los términos y condiciones aquí establecidos.
          </p>

          <h1 className={'Subtitle-1 text-22'}>7. Mensaje Final</h1>
          <p>
            ¡Felices fiestas! Agradecemos tu preferencia y esperamos que disfrutes de la experiencia
            Popnest.
          </p>
        </div>
      </Label>
    </PageContainer>
  );
};

export default TermsAndConditions;
