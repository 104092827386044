import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import Label from '@/components/form/Label/Label';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './HomeFilters.scss';

const FilterButton = (props) => {
  const { label, icon } = props;
  const iconClass = 'fas text-22 m-text-20 fontGray250 ' + icon;
  return (
    <button
      src="spaces/espacio-1.png"
      alt="Foto 1"
      style={{ width: '100%', height: '60px', border: 'none' }}
      className={'bgWhite'}
    >
      <FlexContainer column className={'gap-4'}>
        <i className={iconClass} />
        <Label className={'subTitle2 text-11 m-text-10 fontGray250 margt8'}>{label}</Label>
      </FlexContainer>
    </button>
  );
};

const HomeFilters = () => {
  return (
    <PageContainer
      className={'padtb10 bgWhite'}
      mainContainerClass={'flex-column padlr130 m-padlr10'}
    >
      <FlexContainer className={'flex-column myFilterCarousel'}>
        <Swiper
          navigation
          autoplay={{
            delay: 10500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Navigation]}
          spaceBetween={10}
          slidesPerView={3}
          className="m-padtb10 t-hide m-hide"
        >
          <SwiperSlide>
            <FilterButton label={'Coworkings'} icon={'fa-building'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Terrazas'} icon={'fa-sun'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Salas de Juntas'} icon={'fa-chalkboard'} />
          </SwiperSlide>
        </Swiper>
        <Swiper
          navigation
          autoplay={{
            delay: 10500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Navigation]}
          spaceBetween={10}
          slidesPerView={3}
          className="myFilterCarousel m-padtb0 d-hide"
        >
          <SwiperSlide>
            <FilterButton label={'Coworkings'} icon={'fa-building'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Terrazas'} icon={'fa-sun'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Salas de Juntas'} icon={'fa-chalkboard'} />
          </SwiperSlide>
        </Swiper>
      </FlexContainer>
    </PageContainer>
  );
};

export default HomeFilters;
