import React from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import Label from '@/components/form/Label/Label';
import classNames from 'classnames';

const truncateDescription = (description, limit) => {
  if (description?.length > limit) {
    return description?.substring(0, limit) + '...';
  }
  return description;
};

const NewSpaceCardShortVersion = (props) => {
  const {
    spaceInfo,
    isMobileStyle,
    handleDetailButton,
    isInlineStyle,
    isWhiteBg,
    priceAndCapacityDetails,
  } = props;

  const containerClass = classNames('borderGray050 border-rad8px', {
    'gap-20 m-flex-column': isInlineStyle,
    'flex-column format100': !isInlineStyle,
    margtb20: isMobileStyle,
    bgWhite: isWhiteBg,
  });

  const textContainerClass = classNames('flex-column padlr20', {
    'justify-around': isInlineStyle,
    '': !isInlineStyle,
  });

  let imageItemStyle = {};

  if (isInlineStyle) {
    imageItemStyle = isMobileStyle ? { width: '100%' } : { width: '100%' };
  } else {
    imageItemStyle = isMobileStyle
      ? { height: '00px', width: '100%', borderTopLeftRadius: '18px', borderTopRightRadius: '18px' }
      : {
          width: '100%',
          height: '300px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        };
  }

  const imageToDisplay = (() => {
    try {
      const parsed = JSON.parse(spaceInfo?.main_image);
      return Array.isArray(parsed) && parsed.length > 0 ? parsed[0] : spaceInfo?.main_image;
    } catch {
      return spaceInfo?.main_image;
    }
  })();
  const typeSpanStyle = { zIndex: 2 };

  return (
    <FlexContainer className={containerClass} onClick={handleDetailButton}>
      {spaceInfo?.subtype_title && (
        <span
          style={typeSpanStyle}
          className={'bgWhite border-rad4px text-14 padt12 padb12 padlr10 pos-abs margt20 marglr10'}
        >
          {spaceInfo?.subtype_title}
        </span>
      )}
      <FlexContainer className={'width100'}>
        <img
          src={imageToDisplay}
          alt={spaceInfo?.space_name}
          style={imageItemStyle}
          className={''}
        />
      </FlexContainer>

      <FlexContainer className={textContainerClass}>
        <Label className={'margt20 text-20 align-self-start'}>
          <strong>
            <div
              style={{ fontWeight: 500 }}
              dangerouslySetInnerHTML={{
                __html: truncateDescription(spaceInfo?.space_title, 40),
              }}
            />
          </strong>
        </Label>
        <Label type="text-14 align-self-start">
          {truncateDescription(spaceInfo?.space_address, 30)}
        </Label>
        <Label type="text-14 align-self-start">
          Capacidad: {spaceInfo?.host_capacity} Personas
        </Label>
        {priceAndCapacityDetails && (
          <FlexContainer className={'borderTp margt30 margb10 padt20 width100'}>
            <FlexContainer className={'width100 flex-column gap-6'}>
              <FlexContainer className={'width100'}>
                <Label className={'fontGray6'} type="text-14">
                  {spaceInfo?.host_capacity} Personas
                </Label>
              </FlexContainer>
              <FlexContainer className={'width100'}>
                <Label className={'fontGray6'} type={'text-14'}>
                  Precio por {spaceInfo?.subtype_title === 'Coworking' ? 'Un día' : '1 h'}.
                </Label>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer className={'width100  flex-column gap-10'}>
              <FlexContainer className={'width100'}>
                <Label className={'fontGray400'} type={isInlineStyle ? 'text-18' : 'text-20'}>
                  <strong>
                    $
                    {spaceInfo?.subtype_title === 'Coworking'
                      ? spaceInfo?.rate_24_hours
                      : spaceInfo?.hourly_rate}{' '}
                    MXN
                  </strong>
                </Label>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default NewSpaceCardShortVersion;
