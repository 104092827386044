import React, { useState } from 'react';
import * as Yup from 'yup';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { Formik, Form, Field } from 'formik';
import { TextField, MenuItem, Select } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Label from '@/components/form/Label/Label';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

require('dayjs/locale/es');
dayjs.locale('es');

const formatDateToWords = (date) => {
  try {
    const parsedDate = dayjs(date?.$d);
    return parsedDate.isValid()
      ? parsedDate.format('dddd D [de] MMMM [de] YYYY')
      : 'Por seleccionar';
  } catch {
    return 'por seleccionar';
  }
};

const validationSchema = Yup.object({
  startDate: Yup.date().required('Fecha de inicio requerida').nullable(),
  startTime: Yup.number()
    .required('Hora de inicio requerida')
    .nullable()
    .min(7, 'La hora de inicio no puede ser antes de las 7 AM')
    .max(22, 'La hora de inicio no puede ser después de las 10 PM'),
  endTime: Yup.number()
    .required('Hora de fin requerida')
    .nullable()
    .min(Yup.ref('startTime'), 'La hora de fin no puede ser anterior a la hora de inicio')
    .max(22, 'La hora de fin no puede ser después de las 10 PM'),
  name: Yup.string()
    .required('Nombre requerido')
    .min(3, 'El nombre debe tener al menos 3 caracteres'),
  email: Yup.string()
    .email('Correo electrónico no válido')
    .required('Correo electrónico requerido'),
  phone: Yup.string()
    .required('Teléfono requerido')
    .matches(/^[0-9]+$/, 'El teléfono solo puede contener números')
    .min(8, 'El teléfono debe tener al menos 8 dígitos')
    .max(15, 'El teléfono no puede tener más de 15 dígitos'),
  guestQuantyty: Yup.number()
    .required('Cantidad de participantes requerida')
    .min(1, 'Debe haber al menos un participante')
    .max(100, 'No puede haber más de 100 participantes'),
});

const GuestDataForm = (props) => {
  const { onSubmitFunction, spaceDetail } = props;
  const [showEditFormData, setShowEditFormData] = useState(false);
  const countryOptions = [
    { code: '+1', country: 'US' },
    { code: '+7', country: 'RU' },
    { code: '+20', country: 'EG' },
    { code: '+27', country: 'ZA' },
    { code: '+30', country: 'GR' },
    { code: '+31', country: 'NL' },
    { code: '+32', country: 'BE' },
    { code: '+33', country: 'FR' },
    { code: '+34', country: 'ES' },
    { code: '+36', country: 'HU' },
    { code: '+39', country: 'IT' },
    { code: '+40', country: 'RO' },
    { code: '+41', country: 'CH' },
    { code: '+43', country: 'AT' },
    { code: '+44', country: 'GB' },
    { code: '+45', country: 'DK' },
    { code: '+46', country: 'SE' },
    { code: '+47', country: 'NO' },
    { code: '+48', country: 'PL' },
    { code: '+49', country: 'DE' },
    { code: '+51', country: 'PE' },
    { code: '+52', country: 'MX' },
    { code: '+53', country: 'CU' },
    { code: '+54', country: 'AR' },
    { code: '+55', country: 'BR' },
    { code: '+56', country: 'CL' },
    { code: '+57', country: 'CO' },
    { code: '+58', country: 'VE' },
    { code: '+60', country: 'MY' },
    { code: '+61', country: 'AU' },
    { code: '+62', country: 'ID' },
    { code: '+63', country: 'PH' },
    { code: '+64', country: 'NZ' },
    { code: '+65', country: 'SG' },
    { code: '+66', country: 'TH' },
    { code: '+81', country: 'JP' },
    { code: '+82', country: 'KR' },
    { code: '+84', country: 'VN' },
    { code: '+86', country: 'CN' },
    { code: '+90', country: 'TR' },
    { code: '+91', country: 'IN' },
    { code: '+92', country: 'PK' },
    { code: '+93', country: 'AF' },
    { code: '+94', country: 'LK' },
    { code: '+95', country: 'MM' },
    { code: '+98', country: 'IR' },
    { code: '+211', country: 'SS' },
    { code: '+212', country: 'MA' },
    { code: '+213', country: 'DZ' },
    { code: '+216', country: 'TN' },
    { code: '+218', country: 'LY' },
    { code: '+220', country: 'GM' },
    { code: '+221', country: 'SN' },
    { code: '+222', country: 'MR' },
    { code: '+223', country: 'ML' },
    { code: '+224', country: 'GN' },
    { code: '+225', country: 'CI' },
    { code: '+226', country: 'BF' },
    { code: '+227', country: 'NE' },
    { code: '+228', country: 'TG' },
    { code: '+229', country: 'BJ' },
    { code: '+230', country: 'MU' },
    { code: '+231', country: 'LR' },
    { code: '+232', country: 'SL' },
    { code: '+233', country: 'GH' },
    { code: '+234', country: 'NG' },
    { code: '+235', country: 'TD' },
    { code: '+236', country: 'CF' },
    { code: '+237', country: 'CM' },
    { code: '+238', country: 'CV' },
    { code: '+239', country: 'ST' },
    { code: '+240', country: 'GQ' },
    { code: '+241', country: 'GA' },
    { code: '+242', country: 'CG' },
    { code: '+243', country: 'CD' },
    { code: '+244', country: 'AO' },
    { code: '+245', country: 'GW' },
    { code: '+248', country: 'SC' },
    { code: '+249', country: 'SD' },
    { code: '+250', country: 'RW' },
    { code: '+251', country: 'ET' },
    { code: '+252', country: 'SO' },
    { code: '+253', country: 'DJ' },
    { code: '+254', country: 'KE' },
    { code: '+255', country: 'TZ' },
    { code: '+256', country: 'UG' },
    { code: '+257', country: 'BI' },
    { code: '+258', country: 'MZ' },
    { code: '+260', country: 'ZM' },
    { code: '+261', country: 'MG' },
    { code: '+262', country: 'RE' },
    { code: '+263', country: 'ZW' },
    { code: '+264', country: 'NA' },
    { code: '+265', country: 'MW' },
    { code: '+266', country: 'LS' },
    { code: '+267', country: 'BW' },
    { code: '+268', country: 'SZ' },
    { code: '+269', country: 'KM' },
    { code: '+290', country: 'SH' },
    { code: '+291', country: 'ER' },
    { code: '+297', country: 'AW' },
    { code: '+298', country: 'FO' },
    { code: '+299', country: 'GL' },
    { code: '+350', country: 'GI' },
    { code: '+351', country: 'PT' },
    { code: '+352', country: 'LU' },
    { code: '+353', country: 'IE' },
    { code: '+354', country: 'IS' },
    { code: '+355', country: 'AL' },
    { code: '+356', country: 'MT' },
    { code: '+357', country: 'CY' },
    { code: '+358', country: 'FI' },
    { code: '+359', country: 'BG' },
    { code: '+370', country: 'LT' },
    { code: '+371', country: 'LV' },
    { code: '+372', country: 'EE' },
    { code: '+373', country: 'MD' },
    { code: '+374', country: 'AM' },
    { code: '+375', country: 'BY' },
    { code: '+376', country: 'AD' },
    { code: '+377', country: 'MC' },
    { code: '+378', country: 'SM' },
    { code: '+379', country: 'VA' },
    { code: '+380', country: 'UA' },
    { code: '+381', country: 'RS' },
    { code: '+382', country: 'ME' },
    { code: '+383', country: 'XK' },
    { code: '+385', country: 'HR' },
    { code: '+386', country: 'SI' },
    { code: '+387', country: 'BA' },
    { code: '+389', country: 'MK' },
    { code: '+420', country: 'CZ' },
    { code: '+421', country: 'SK' },
    { code: '+423', country: 'LI' },
    { code: '+500', country: 'FK' },
    { code: '+501', country: 'BZ' },
    { code: '+502', country: 'GT' },
    { code: '+503', country: 'SV' },
    { code: '+504', country: 'HN' },
    { code: '+505', country: 'NI' },
    { code: '+506', country: 'CR' },
    { code: '+507', country: 'PA' },
    { code: '+508', country: 'PM' },
    { code: '+509', country: 'HT' },
    { code: '+590', country: 'GP' },
    { code: '+591', country: 'BO' },
    { code: '+592', country: 'GY' },
    { code: '+593', country: 'EC' },
    { code: '+594', country: 'GF' },
    { code: '+595', country: 'PY' },
    { code: '+596', country: 'MQ' },
    { code: '+597', country: 'SR' },
    { code: '+598', country: 'UY' },
    { code: '+670', country: 'TL' },
    { code: '+672', country: 'AQ' },
    { code: '+673', country: 'BN' },
    { code: '+674', country: 'NR' },
    { code: '+675', country: 'PG' },
    { code: '+676', country: 'TO' },
    { code: '+677', country: 'SB' },
    { code: '+678', country: 'VU' },
    { code: '+679', country: 'FJ' },
    { code: '+680', country: 'PW' },
    { code: '+681', country: 'WF' },
    { code: '+682', country: 'CK' },
    { code: '+683', country: 'NU' },
    { code: '+685', country: 'WS' },
    { code: '+686', country: 'KI' },
    { code: '+687', country: 'NC' },
    { code: '+688', country: 'TV' },
    { code: '+689', country: 'PF' },
    { code: '+690', country: 'TK' },
    { code: '+691', country: 'FM' },
    { code: '+692', country: 'MH' },
    { code: '+850', country: 'KP' },
    { code: '+852', country: 'HK' },
    { code: '+853', country: 'MO' },
    { code: '+855', country: 'KH' },
    { code: '+856', country: 'LA' },
    { code: '+880', country: 'BD' },
    { code: '+886', country: 'TW' },
    { code: '+960', country: 'MV' },
    { code: '+961', country: 'LB' },
    { code: '+962', country: 'JO' },
    { code: '+963', country: 'SY' },
    { code: '+964', country: 'IQ' },
    { code: '+965', country: 'KW' },
    { code: '+966', country: 'SA' },
    { code: '+967', country: 'YE' },
    { code: '+968', country: 'OM' },
    { code: '+971', country: 'AE' },
    { code: '+972', country: 'IL' },
    { code: '+973', country: 'BH' },
    { code: '+974', country: 'QA' },
    { code: '+975', country: 'BT' },
    { code: '+976', country: 'MN' },
    { code: '+977', country: 'NP' },
    { code: '+992', country: 'TJ' },
    { code: '+993', country: 'TM' },
    { code: '+994', country: 'AZ' },
    { code: '+995', country: 'GE' },
    { code: '+996', country: 'KG' },
    { code: '+998', country: 'UZ' },
  ];
  const searchFormData = useSelector((state) => state.searchFormData);
  const spaceTypeFormData = searchFormData?.spaceType;

  console.log('ver los datos ', searchFormData);
  return (
    <Formik
      initialValues={{
        startDate: searchFormData?.startDate ? searchFormData?.startDate : null,
        startTime: searchFormData?.startTime ? searchFormData?.startTime : 9,
        endTime: searchFormData?.endTime ? searchFormData?.endTime : 17,
        name: '',
        email: '',
        phone: '',
        countryCode: '+52',
        guestQuantyty: searchFormData?.guests ? searchFormData?.guests : 1,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ setFieldValue, values, isValid, dirty, errors, touched }) => {
        const hours = values.endTime - values.startTime;
        const pricexTime =
          spaceTypeFormData === 'coworking' ? spaceDetail?.rate_24_hours : spaceDetail?.hourly_rate;
        const totalPrice = () => {
          if (spaceTypeFormData === 'coworking') {
            return pricexTime * (values.guestQuantyty || 1);
          } else {
            return pricexTime * (hours > 0 ? hours : 0);
          }
        };

        const timeLabel = spaceTypeFormData === 'coworking' ? 'por día' : '/h';

        const guestLabel = 'Personas: ' + values.guestQuantyty + ' personas';
        const dateLabel = 'Fecha: ' + formatDateToWords(values?.startDate);
        const startTime = 'Hora de inicio: ' + values?.startTime + ':00 horas';
        const endTime = 'Hora de Fin: ' + values?.endTime + ':00 horas';

        console.log('Errores:', errors);
        console.log('Campos tocados:', touched);

        const imagesArray = (() => {
          try {
            const parsed = JSON.parse(spaceDetail?.main_image);
            return Array.isArray(parsed) && parsed.length > 0
              ? parsed
              : [spaceDetail?.main_image].filter(Boolean);
          } catch {
            return [spaceDetail?.main_image].filter(Boolean);
          }
        })();

        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Form>
              <FlexContainer column className={'gap-20'}>
                {!showEditFormData && (
                  <>
                    <img src={imagesArray[0]} alt={''} className={'width100 d-hide'} />
                    <label className={'text-18 m-text-20 Subtitle-3'}>Datos de la reserva</label>
                    <FlexContainer className={'justify-between'}>
                      <label className={'text-16 m-text-14 '}>{guestLabel} </label>
                      <button
                        type="button"
                        onClick={() => setShowEditFormData(true)}
                        className={'bgWhite fontGray250 borderNone padlr10 padtb4 text-12'}
                      >
                        Editar <i className="fa fa-pencil" />
                      </button>
                    </FlexContainer>
                    <FlexContainer className={'justify-between'}>
                      <label className={'text-16 m-text-14 '}>{dateLabel} </label>
                      <button
                        type="button"
                        onClick={() => setShowEditFormData(true)}
                        className={'bgWhite fontGray250 borderNone padlr10 padtb4 text-12'}
                      >
                        Editar <i className="fa fa-pencil" />
                      </button>
                    </FlexContainer>
                    <FlexContainer className={'justify-between'}>
                      <label className={'text-16 m-text-14 '}>{startTime} </label>
                      <button
                        type="button"
                        onClick={() => setShowEditFormData(true)}
                        className={'bgWhite fontGray250 borderNone padlr10 padtb4 text-12'}
                      >
                        Editar <i className="fa fa-pencil" />
                      </button>
                    </FlexContainer>
                    <FlexContainer className={'justify-between'}>
                      <label className={'text-16 m-text-14 '}>{endTime} </label>
                      <button
                        type="button"
                        onClick={() => setShowEditFormData(true)}
                        className={'bgWhite fontGray250 borderNone padlr10 padtb4 text-12'}
                      >
                        Editar <i className="fa fa-pencil" />
                      </button>
                    </FlexContainer>
                  </>
                )}
                {showEditFormData && (
                  <FlexContainer className={'flex-column'}>
                    <Field name="startDate">
                      {({ field, form }) => (
                        <DatePicker
                          label="Fecha de inicio de reservación"
                          value={values.startDate ? dayjs(values.startDate) : null}
                          minDate={dayjs()}
                          onChange={(newValue) => {
                            setFieldValue(
                              'startDate',
                              newValue ? dayjs(newValue).format('YYYY-MM-DD') : '',
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(form.errors.startDate && form.touched.startDate)}
                              helperText={form.touched.startDate && form.errors.startDate}
                            />
                          )}
                        />
                      )}
                    </Field>
                    <Field name="guestQuantyty">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label="Número de Personas"
                          variant="outlined"
                          type="number"
                          onChange={(e) => setFieldValue('guestQuantyty', e.target.value)}
                          error={Boolean(form.errors.guestQuantyty && form.touched.guestQuantyty)}
                          helperText={form.touched.guestQuantyty && form.errors.guestQuantyty}
                        />
                      )}
                    </Field>
                    <Field name="startTime">
                      {({ field, form }) => (
                        <div>
                          <Label className={'margtb8'}>
                            Hora de inicio: {form.values.startTime}:00
                          </Label>
                          <Slider
                            min={7}
                            max={22}
                            value={form.values.startTime}
                            onChange={(value) => form.setFieldValue('startTime', value)}
                            trackStyle={{ backgroundColor: '#716cbf' }}
                            handleStyle={{ borderColor: '#716cbf' }}
                          />
                          {form.errors.startTime && form.touched.startTime && (
                            <div className="text-red-600">{form.errors.startTime}</div>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="endTime">
                      {({ field, form }) => (
                        <div>
                          <Label className={'margtb8'}>Hora de fin: {form.values.endTime}:00</Label>
                          <Slider
                            min={7}
                            max={22}
                            value={form.values.endTime}
                            onChange={(value) => form.setFieldValue('endTime', value)}
                            trackStyle={{ backgroundColor: '#716cbf' }}
                            handleStyle={{ borderColor: '#716cbf' }}
                          />
                          {form.errors.endTime && form.touched.endTime && (
                            <div className="text-red-600">{form.errors.endTime}</div>
                          )}
                        </div>
                      )}
                    </Field>
                    <button
                      type="button"
                      onClick={() => setShowEditFormData(false)}
                      className={
                        'bgWhite fontGray250 width100 bold border-rad8px borderGray050 grayShadow16 margtb10 padlr40 padtb20 text-12'
                      }
                    >
                      Culminar edición <i className="fa fa-pencil" />
                    </button>
                  </FlexContainer>
                )}
                <label className={'text-18 m-text-20 Subtitle-3 margt10'}>
                  Datos del titular de la reserva
                </label>
                <Field name="name">
                  {({ field, form }) => (
                    <TextField
                      {...field}
                      label="Nombre Completo"
                      variant="outlined"
                      error={Boolean(form.errors.name && form.touched.name)}
                      helperText={form.touched.name && form.errors.name}
                    />
                  )}
                </Field>
                <Field name="email">
                  {({ field, form }) => (
                    <TextField
                      {...field}
                      label="Email"
                      variant="outlined"
                      error={Boolean(form.errors.email && form.touched.email)}
                      helperText={form.touched.email && form.errors.email}
                    />
                  )}
                </Field>
                <Field name="countryCode">
                  {({ field }) => (
                    <Select
                      {...field}
                      label="Código de país"
                      variant="outlined"
                      onChange={(e) => setFieldValue('countryCode', e.target.value)}
                    >
                      {countryOptions.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.code} ({option.country})
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
                <Field name="phone">
                  {({ field, form }) => (
                    <TextField
                      {...field}
                      label="Teléfono"
                      variant="outlined"
                      error={Boolean(form.errors.phone && form.touched.phone)}
                      helperText={form.touched.phone && form.errors.phone}
                    />
                  )}
                </Field>
                {!isValid && dirty && (
                  <Label type="Subtitle-1" className={'fontRed100 margb10 text-10 text-center'}>
                    Por favor, revisa los campos. Algunos contienen errores o están incompletos.
                  </Label>
                )}
                <label className={'text-18 m-text-20 Subtitle-3 margt10'}>Método de pago</label>
                <FlexContainer className={'justify-between'}>
                  <label className={'text-16 m-text-14 '}>Subtotal:</label>
                  <label className={'text-16 m-text-14 '}>
                    <strong>${pricexTime + timeLabel}</strong>
                  </label>
                </FlexContainer>
                <FlexContainer className={'justify-between'}>
                  <label className={'text-16 m-text-14 '}>
                    <strong>${totalPrice().toFixed(2)}</strong>
                  </label>
                </FlexContainer>
                <FlexContainer
                  className={'justify-between borderGray100 justify-between padlr10 padtb10'}
                >
                  <label className={'text-16 m-text-14'}>
                    <strong>Total</strong>
                  </label>
                  <label>
                    <strong className={'text-20 m-text-18'}>${totalPrice().toFixed(2)}</strong>
                  </label>
                </FlexContainer>
              </FlexContainer>

              <button
                onClick={() => onSubmitFunction(values)}
                type="submit"
                className={
                  'bgBlue400 width100 border-rad24px borderGray050 fontWhite grayShadow16 margtb30 padlr40 padtb10 text-18'
                }
                disabled={!isValid || !dirty}
              >
                <strong>Pagar</strong>
              </button>
            </Form>
          </LocalizationProvider>
        );
      }}
    </Formik>
  );
};

const ReservationForm = (props) => {
  const { className, onSubmitFunction, spaceDetail } = props;
  const containerClass = className + ' flex-column border-rad16px margt4 margb20';
  return (
    <FlexContainer className={containerClass}>
      <FlexContainer className={'m-flex-column width100 gap-20'}>
        <FlexContainer className={'flex-column width100'}>
          <Label type="width100 text-18 m-text-20 Subtitle-3">¡Casi todo listo!</Label>
          <Label type="width100 padtb20 text-16">
            Verifica la información de tu reserva y completa los datos.
          </Label>
          <GuestDataForm onSubmitFunction={onSubmitFunction} spaceDetail={spaceDetail} />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ReservationForm;
