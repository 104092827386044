import React, { useEffect } from 'react';
import { useTermsAndConditionsPromo } from './hooks/TermsAndConditionsPromo.hook';
import TermsAndConditionsPromoView from './views/TermsAndConditionsPromoView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const TermsAndConditionsPromoController = () => {
  const { isLoading, spacesData } = useTermsAndConditionsPromo();
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return <TermsAndConditionsPromoView spacesData={spacesData} />;
  }
};

export default TermsAndConditionsPromoController;
