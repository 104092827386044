import React, { useEffect } from 'react';
import { useBlog } from './hooks/Blog.hook';
import BlogView from './views/BlogView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const BlogController = () => {
  const { isLoading, spacesDataFromBackend } = useBlog();
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return <BlogView spacesDataFromBackend={spacesDataFromBackend} />;
  }
};

export default BlogController;
